<script>
import FormFieldInput from "@components/form-field-input";

export default {
    name: "LoginComponent",
    components: {
        FormFieldInput,
    },
    data() {
        return {
            password: "",
            email: "",
        };
    },
    methods: {
        async login() {
            try {
                const { isValid, message } = this.$utility.loginValidation(
                    this.email,
                    this.password
                );
                if (!isValid) {
                    return this.$utility.showErrorMessage(message);
                }

                const result = await this.$http.postWithoutHeaders(
                    "common/login",
                    {
                        email: this.email,
                        password: this.password,
                    }
                );

                this.$store.dispatch("auth/login", result.object);
                this.$utility.showSuccessMessage("You've been logged in");
            } catch (err) {
                console.log(err);
                this.$utility.showErrorMessage(err.message);
            }
        },
    },
};
</script>

<template lang="pug">
.flex.justify-center.items-center.flex-col.h-screen.bg-gray-200.px-6 
    h2.text-center.text-4xl.mb-8.res-h1 Dealer/Manufacturer Login
    .p-6.max-w-sm.w-full.bg-white.shadow-md.rounded-md  
        //- form.mt-4(@submit.prevent="login")
        form-field-input(
            inputType="email",
            v-model="email",
            placeholder="Enter Email",
            attribute="email",
            label="Enter your email"
        )
        form-field-input(
            inputType="password",
            v-model="password",
            placeholder="Enter your password!",
            attribute="password",
            :minLength="6",
            label="Enter your Password"
        )
            //.flex.justify-between.items-center.mt-4
                div
                    label.inline-flex.items-center
                        input.form-checkbox.text-indigo-600(type="checkbox")
                        span.mx-2.text-gray-600.text-sm Remember me
                div
                    a.block.text-sm.fontme.text-indigo-700(
                        class="hover:underline",
                        href="#"
                    ) Forgot your password?
        .mt-6
            button.py-2.px-4.text-center.bg-indigo-600.rounded-md.w-full.text-white.text-sm(
                type="submit",
                class="hover:bg-indigo-500",
                @click="login"
            )
                | Sign in
</template>
